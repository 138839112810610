<template>
  <div v-if="video" class="ld-video-section">
    <div class="inner-container fad">
      <div class="ld-video-section__flex">
        <div class="ld-video-section__video">
          <!-- <ImageLazy
            :src="image.filename"
            :alt="image.alt"
            :title="image.title"
          /> -->
          <video v-if="video?.filename" width="320" height="240" controls>
            <source :src="video.filename" type="video/mp4" />
          </video>
        </div>
        <div class="ld-video-section__txt">
          <h2 class="ld-video-section__title">
            {{ title }}
          </h2>
          <p class="landing-download__subtitle" v-html="text" />
          <!-- <main-button
            label="Voir la vidéo"
            :link="localePath('index')"
            icon-position="RIGHT"
            class="ld-video-section__btn"
            class-variant="btn-white-blue full-width-sm"
          >
            <icon-arrow-right />
          </main-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MainButton from '@/components/Buttons/MainButton/MainButton';
// import IconArrowRight from '@/icons/IconArrowRight';

export default {
  name: 'SbVideoSection',
  components: {
    // MainButton,
    // IconArrowRight,
  },
  props: {
    video: { type: Object, default: () => {} },
    image: { type: Object, default: () => {} },
    title: { type: String, default: undefined },
    text: { type: String, default: undefined },
  },
}
</script>

<style lang="scss">
.ld-video-section {
  background-color: var(--landing-color-secondary);
  margin: 60px 0;

  &__title {
    margin-bottom: 20px;
    color: var(--white);
  }

  p {
    color: var(--white);
  }

  &__flex {
    padding: 60px 0;

    @include mqmin($mq-sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > * {
        flex: 0 0 calc(50% - 20px / 2);

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  &__video {
    @include mq($mq-sm) {
      margin-bottom: 20px;
    }

    video {
      display: block;
      width: 100%;
      border-radius: 20px;
    }
  }
}
</style>
